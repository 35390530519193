<style lang="less" scoped>
	.content {
		background-color: #ffffff;

		.head {
			background-repeat: no-repeat;
			background-size: cover;
			height: 560px;
			text-align: left;
			padding: 352px 0 0 0;

			.title {
				width: 1360px;
				margin: 0 auto;
				font-size: 58px;
				color: #ffffff;
				text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.5);
			}

			.txt {
				width: 1360px;
				margin: 0 auto;
				font-size: 35px;
				padding-top: 20px;
				color: #ffffff;
				text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.5);
			}
		}

		.content_big_box {
			padding: 58px 0;
			cursor: pointer;
		}

		.content_box {
			width: 1360px;
			height: 670px;
			box-shadow: 6px 4px 9px 0px rgba(186, 186, 186, 0.5);

			.img {
				height: 560px;
				width: 100%;
			}
		}

		.box {
			width: 1362px;
			margin: 0 auto;
			padding-top: 80px;
		}

		.notes {
			height: 58px;
			line-height: 58px;
			background: #f5f5f5;
			font-size: 18px;
			color: #666666;
		}
	}

	/deep/ .el-carousel--horizontal {
		height: 560px;
	}

	/deep/ .el-carousel__container {
		height: 560px;
	}

	.productName {
		display: flex;
		line-height: 110px;
		padding: 0 33px;

		.left {
			font-size: 38px;
			width: 50%;
			text-align: left;
		}

		.right {
			font-size: 24px;
			color: #999999;
			width: 50%;
			text-align: right;
		}
	}
</style>
<template>
	<div class="content">
		<div class="head" v-if="image" :style="{background: 'url('+ image +')'}" style="background-repeat: no-repeat;background-size: cover;">
			<div class="title">{{$t('productDisplay.title')}}</div>
			<div class="txt">{{$t('productDisplay.text')}}</div>
		</div>
		<div class="box">
			<div class="notes">{{$t('productDisplay.notes')}}</div>
			<div class="content_big_box" v-for="(item,index) in contentList" :key="index" @click.stop="$router.push({path:'productDisplayDetails',query:{id:item.id}})">
				<div class="content_box">
					<div>
						<el-carousel>
							<el-carousel-item v-for="(items,i) in item.en_images" :key="i">
								<img :src="items"
									class="img"  style="object-fit: cover;"/>
							</el-carousel-item>
						</el-carousel>
					</div>
					<div class="productName">
						<div class="left">{{item.en_name}}</div>
						<div class="right">{{$t('productDisplay.time')}}：{{item.update_time}}</div>
					</div>
				</div>
			</div>
		</div>

	</div>
</template>
<script>
	export default {
		components: {},
		data() {
			return {
				contentList:[],
				image:""
			};
		},
		mounted() {
			this.productDisplayList()
			this.imageList()
		},
		methods: {
			async imageList() {
				try {
					let _this = this
					let params = {
						position_id:3
					}
					let res = await this.$axios.get('ads/getList', {
						params: params
					});
					if (res.code == 1) {
						this.image =  'https://cultural-creation.oss-cn-beijing.aliyuncs.com/'+res.data[0].image
					}
				} catch (error) {
					console.log(error);
				}
			},
			async productDisplayList() {
				try {
					let _this = this
					let params = {
						page: 1,
					}
					let res = await this.$axios.get('product/index', {
						params: params
					});
					console.log(res)
					if (res.code == 1) {
						this.contentList = res.data
						this.contentList.forEach(item=>{
							item.update_time=this.$util.formatTimeTwo(item.update_time, 'Y-M-D')
						})
						// this.$router.push('personalCenter')
					}
				} catch (error) {
					console.log(error);
				}
			},
		}
	}
</script>
